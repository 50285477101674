function sanitize(str) {
  return str.toLowerCase()
    .replace(/^\s+|\s+$/gm, '')
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function resolveLink(record, section) {
  const father = record.parent?.id ? record.parent : false
  const grandFather = father?.parent
  const greatGrandFather = grandFather?.parent
  const title = sanitize(record.title)
  const separator = section == "glossario" || section == "glossary"
    ? father
      ? `#${title}` : `/${record.slug}`
    : `/${record.slug}`

  if (greatGrandFather) {
    return `${section}/${greatGrandFather.slug}/${grandFather.slug}/${father.slug}${separator}`;
  }
  else if (grandFather) {
    return `${section}/${grandFather.slug}/${father.slug}${separator}`;
  }
  else if (father) {
    return `${section}/${father.slug}${separator}`;
  }
  else {
    return `${section}${separator}`;
  }
}