import { useRouter } from 'next/router';
import Link from 'next/link';

import Head from 'next/head';
import Layout from 'components/Layout';
import StructuredContent from 'components/StructuredContent';
import * as queries from 'lib/queries';
import { doQuery } from 'lib/api';

function Home({ greeting, menuLabels, pages, page, locale }) {
  const { abstract, content } = page;

  const router = useRouter();
  const path = router.asPath;
  const parentPath = path.split('/')[1];

  const match = menuLabels
    ? menuLabels
        ?.filter((l) => l.slug == parentPath)
        .map((label) => {
          return label;
        })
    : '';

  const root =
    match && match.length > 0 && match[0]?.slug != ''
      ? `/${match[0]?.slug}/`
      : './';

  return (
    <Layout locale={locale} pages={pages} page={page} menuLabels={menuLabels}>
      <Head>
        <title>{page.title}</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="prose prose-lg">
        <h1 className="lg:text-[40px] text-[30px] font-bold">{page.title}</h1>
        {abstract && (
          <div
            className="my-4 text-[22px] leading-[1.5]"
            dangerouslySetInnerHTML={{ __html: abstract }}
          />
        )}
        <StructuredContent content={content} locale={locale} />
      </div>
      <div className="lg:hidden mb-[75px]">
        <ul className="flex flex-wrap justify-between">
          {pages
            ?.filter((p) => p.parent == null)
            .map((page) => {
              return (
                <li
                  key={page.id}
                  className="md:basis-[49%] basis-[100%] px-[10px] py-[5px] border-[1px] my-[10px]"
                >
                  <Link href={`${root}${page.slug}`}>
                    <a className="font-bold">{page.title}</a>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </Layout>
  );
}

export async function getStaticProps({ locale, preview }) {
  // const message = locale === 'it' ? 'la lingua corrente è ' : 'current language is';
  const responsePages = await doQuery(
    queries.allPublicPages,
    { locale },
    preview
  );
  const pages = responsePages?.data?.guidelinesSlugs || [];

  const response = await doQuery(
    queries.publicGuidelinesIndex,
    { locale },
    preview
  );

  const page = response?.data?.publicGuidelinesIndex || null;

  const responseMenu = await doQuery(queries.sections, { locale }, preview);
  let menuLabelPublic = responseMenu?.data?.publicGuidelinesIndex || null;
  const menuLabelPrivate = responseMenu?.data?.privateGuidelinesIndex || null;
  const menuLabelGlossary = responseMenu?.data?.glossaryIndex || null;
  if (menuLabelPublic) {
    menuLabelPublic.slug = '';
  }
  const menuLabels = [menuLabelPublic, menuLabelPrivate, menuLabelGlossary];

  return {
    props: {
      // greeting: `${message} "${locale}"`,
      locale,
      pages,
      page,
      menuLabels,
    },
  };
}

export default Home;
