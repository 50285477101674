import { useEffect } from 'react';
import { StructuredText } from 'react-datocms';
import { renderRule, isHeading, isList, isListItem } from 'datocms-structured-text-utils';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import Fancybox from 'components/Fancybox'
import ReactPlayer from "react-player";

import Link from 'next/link';
import { Image as DatoImage } from 'react-datocms';
import { DocumentDownloadIcon } from '@heroicons/react/solid'
import { resolveLink } from '../lib/utils/pathUtils'


const StructuredContent = ({ locale, content }) => {

  const renderBlock = (record) => {
    switch (record.__typename) {
      case "VideoBlockRecord": {
        if (record.internalVideo?.url){
          const mp4Url = record.internalVideo.video.mp4Url;
          return (
            <div key={record.id} className="my-8">
              <div key={record.id} className="text-xl mb-2">
                {record.title}
              </div>
              <div className="max-w-[600px] aspect-video border-[1px] border-gray shadow-[0_2px_14px_-4px_rgba(0,0,0,0.3)]">
                <ReactPlayer
                  fluid={true}
                  playing={true}
                  autoPlay={false}
                  width="100%"
                  height="100%"
                  light={record.poster?.responsiveImage.src}
                  lightUrl={record.poster?.responsiveImage.src}
                  url={mp4Url}
                  controls={true}
                  className="react-player-custom"
                />
              </div>
            </div>
          );
        }
        if (record.externalVideo?.url){
          return (
            <div key={record.id} className="my-8">
              <div key={record.id} className="text-xl mb-2">
                {record.title}
              </div>
              <div className=" max-w-[600px] aspect-video border-[1px] border-gray shadow-[0_2px_14px_-4px_rgba(0,0,0,0.3)]">
                <ReactPlayer
                  fluid={true}
                  playing={false}
                  autoPlay={false}
                  width="100%"
                  height="100%"
                  url={record.externalVideo?.url}
                  controls={true}
                  className="react-player-custom"
                />
              </div>
            </div>
          );
        }
      }
      case "IframeBlockRecord": {
        if (!record.url) {
          return null;
        }
        return (
          <div key={record.id}>
            <iframe className="w-full max-w-[800px] border-[1px] border-gray shadow-[0_2px_14px_-4px_rgba(0,0,0,0.3)]" width="800" height="450" src={record.url}></iframe>
          </div>
        );
      }
      case "ImageRecord": {
        if (!record?.bigImage?.responsiveImage) {
          return null;
        }
        return (
          <div key={record.id}>
            <div className="my-2 lg:my-10 2xl:my-16 max-w-[600px] border-[1px] border-grayL shadow-[0_2px_14px_-4px_rgba(0,0,0,0.3)]">
              <Fancybox>
                <a
                title={record?.thumbImage?.responsiveImage.title}
                data-fancybox={record.id}
                data-caption={record.caption}
                href={record?.bigImage.responsiveImage.src}
                >
                  <DatoImage
                    className="dato-image-cover"
                    data={record?.thumbImage?.responsiveImage}
                    alt={record?.thumbImage?.responsiveImage.alt}
                    title={record?.thumbImage?.responsiveImage.title}
                  />
                </a>
                  {record.caption &&
                    <div className="border-t border-grayL text-normal text-xs md:text-sm p-3">{record.caption}</div>
                  }
              </Fancybox>
            </div>
          </div>
        );
      }
      case "AttachmentBlockRecord": {
        if (!record?.file?.url) {
          return null;
        }
        return (
          <div key={record.id}>
            <div className="py-2">
              <Link
                href={record.file.url}
                key={record.id}
              >
                <a
                  className="underline hover:no-underline"
                  target="_blank"
                  noopener="true"
                  noreferrer="true"
                >
                  <DocumentDownloadIcon className="inline align-text-top lg:w-[22px] lg:h-[22px] lg:mb-[0] w-[16px] h-[16px] mb-[5px] text-primary mr-2" />
                  {record.title}
                </a>
              </Link>
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    // <div className="text-[18px] leading-[1.5]">
    // <div className="prose prose-lg">
    <StructuredText
      data={content}
      renderInlineRecord={({ record }) => {
        const resolved = resolveLink(record);
        return (
          <Link href={resolved} key={record.id} locale={locale}>
            <a className="underline">{record.title}</a>
          </Link>
        );
      }}
      renderLinkToRecord={({ record, children, transformedMeta }) => {
        return (
          <Link
            {...transformedMeta}
            href={resolveLink(record)}
            key={record.id}
            locale={locale}
          >

            <a className="underline">{children}</a>
          </Link>
        );
      }}
      renderBlock={({ record }) => renderBlock(record)}
      customRules={[
        renderRule(isHeading, ({ node, children, key }) => {
          if (!children) return null;
          const Tag = `h${node.level}`;
          const anchor = toPlainText(node)
            .toLowerCase()
            .replace(/^\s+|\s+$/gm, '')
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');

          return (
            <div className="relative" key={key}>
              <span className="absolute mt-[-75px]" id={anchor}></span>
              <Tag>
                {children}
              </Tag>
            </div>
          );
        }),
        renderRule(isList, ({ node, children, key }) => {
          if (!children) return null;
          return (
            <ul className="ml-[30px] list-disc" key={key}>
              {children}
            </ul>
          );
        }),
        renderRule(isListItem, ({ node, children, key }) => {
          if (!children) return null;
          return (
            <li className="my-[10px]" key={key}>
              {children}
            </li>
          );
        }),
      ]}
    />
    // </div>
  );
};

export default StructuredContent;